document.addEventListener('DOMContentLoaded', function() {
    
    let backgroundPlayer;

    // Initialize the background player
    function initializeBackgroundPlayer(backgroundIframe) {
        if (backgroundIframe) {
            backgroundPlayer = new Vimeo.Player(backgroundIframe);
        }
    }

    // Open the modal
    document.querySelectorAll('[data-toggle="modal-video"]').forEach(function(button) {
        button.addEventListener('click', function() {

            const videoSource = button.getAttribute('data-video-source');
            const videoTarget = button.getAttribute('data-video-target');
            const modal = document.getElementById('modalVideo');
            const modalContent = modal.querySelector('.modal-video--content');

            // Clear previous content
            modalContent.innerHTML = '';

            // Get the video element by ID
            const videoElement = document.getElementById(videoTarget);

            if (videoElement) {
                if (videoSource === 'vimeo') {
                    initializeBackgroundPlayer(videoTarget);

                    // For Vimeo, clone the player <iframe>
                    const iframeClone = videoElement.cloneNode(true);

                    // Modify the src attribute to unmute and disable looping
                    let src = iframeClone.getAttribute('src');

                    // Create a URL object to manipulate the parameters
                    const url = new URL(src, window.location.origin);

                    // Set desired parameters, remove any unnecessary
                    url.searchParams.delete('background');

                    url.searchParams.set('autoplay', '0');
                    url.searchParams.set('loop', '0');
                    url.searchParams.set('muted', '0');
                    url.searchParams.set('controls', '1');
                    url.searchParams.set('byline', '0');
                    url.searchParams.set('title', '0');
                    url.searchParams.set('portrait', '0');

                    // Set the updated src on the cloned <iframe>
                    iframeClone.setAttribute('src', url.href);

                    iframeClone.style.width = '100%';
                    iframeClone.style.height = '100%';

                    // Append cloned <iframe> to modal content
                    modalContent.appendChild(iframeClone);

                    // Create a Vimeo Player instance for the modal video; otherwise interacting with modal inadvertently affects the background content video
                    const modalPlayer = new Vimeo.Player(iframeClone);

                    // Pause the background video
                    if (backgroundPlayer) {
                        backgroundPlayer.pause().catch(function(error) {
                            console.error('Error pausing background video:', error);
                        });
                    }

                    // Play the modal video
                    modalPlayer.play().catch(function(error) {
                        console.error('Error playing modal video:', error);
                    });

                } else {
                    // Create new video element for modal
                    const videoElement = document.createElement('video');
                    
                    // Get the full video element by ID for reference
                    const originalVideo = document.getElementById(videoTarget);
                    
                    // Find all source elements in the original video
                    const originalSources = originalVideo.getElementsByTagName('source');
                    if (originalSources.length > 0) {
                        // Clone each source element and its attributes
                        Array.from(originalSources).forEach(source => {
                            const newSource = document.createElement('source');
                            // Copy all attributes from original source
                            Array.from(source.attributes).forEach(attr => {
                                newSource.setAttribute(attr.name, attr.value);
                            });
                            videoElement.appendChild(newSource);
                        });
                    } else {
                        // Fallback: try to get src directly from video element
                        const videoSrc = originalVideo.getAttribute('src');
                        if (videoSrc) {
                            const source = document.createElement('source');
                            source.src = videoSrc;
                            source.type = 'video/mp4';
                            videoElement.appendChild(source);
                        }
                    }

                    // Set video attributes
                    videoElement.setAttribute('controls', true);
                    videoElement.setAttribute('loop', false);
                    videoElement.setAttribute('muted', false);
                    videoElement.setAttribute('autoplay', false);
                    videoElement.style.width = '100%';

                    // Append to modal content
                    modalContent.appendChild(videoElement);

                    // Ensure the video is paused and reset to the beginning
                    videoElement.pause();
                    videoElement.currentTime = 0;
                }
            } else {
                console.error('Video element not found with ID:', videoTarget);
            }

            // We have our video, now display the modal
            modal.style.display = 'block';
        });
    });

    // Close the modal
    function closeModal() {
        const modal = document.getElementById('modalVideo');
        modal.style.display = 'none';

        // Remove the <video> or <iframe> element
        modal.querySelector('.modal-video--content').innerHTML = '';

        // Resume the background video
        if (backgroundPlayer) {
            backgroundPlayer.play().catch(function(error) {
                console.error('Error resuming background video:', error);
            });
        }
    }

    document.querySelectorAll('.modal-video--close').forEach(function(closeButton) {
        closeButton.addEventListener('click', closeModal);
    });

    // Close modal when clicking on backdrop/outside of the content
    window.addEventListener('click', function(event) {
        const modal = document.getElementById('modalVideo');
        if (event.target === modal) {
            closeModal();
        }
    });
});
